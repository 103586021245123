import * as React from "react";
import styled from "styled-components";
import {
  ProductCarousel,
  Features,
  ArticleBanner,
  SponsorCard,
  ResourceCard,
  SeoData,
  ExitRamp,
} from "components";
import {
  H2,
  H3,
  H4,
  P,
  Container,
  SmallContainer,
  FlexGrid,
  Grid,
  Button,
} from "styles";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import { useIntersection } from "hooks";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const IndexPage = ({ data }) => {
  const [elRef, observerEntry] = useIntersection({ threshold: 0 });
  const { frontmatter: article } = data.markdownRemark;
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.25, duration: 0.75 }}
    >
      <SeoData pageSpecificTitle="Home" pageSpecificDescription="AeroChamber® Plus® Flow-Vu® is an anti-static valved holding chamber designed with patients in mind. Patients can use an AeroChamber® Plus® Flow-Vu® to help improve medication delivery from a metered dose inhaler (MDI)" />
      <ProductCarousel />
      {/* <ArticleBanner article={article} /> */}
      <SmallContainer ref={elRef}>
        {observerEntry.isIntersecting && (
          <VideoContainer
            initial={{ opacity: 0 }}
            animate={
              observerEntry.isIntersecting ? { opacity: 1 } : { opacity: 0 }
            }
          >
            <iframe
              title="Using your inhaler"
              width="1200"
              height="720"
              allowFullScreen
              src="https://player.vimeo.com/video/840790217?dnt=1;title=0;byline=0;portrait=0;speed=0;badge=0;autopause=0;player_id=0;app_id=58479"
            />
          </VideoContainer>
        )}

        <P style={{ textAlign: "center", margin: "1rem auto" }}>
          <small>AER-IE-00009 | Date of Prep: May 2021</small>
        </P>
      </SmallContainer>
      <SmallContainer>
        <H2 centered style={{ marginBottom: "4rem", fontWeight: "300" }}>
          Why You Should Use A Spacer
        </H2>
        <H3
          centered
          style={{
            fontWeight: "500",
            fontSize: "1.25rem",
            marginBottom: "1.3em",
            lineHeight: "1.3em",
          }}
        >
          Using an AeroChamber Plus<sup>®</sup> Flow-Vu<sup>®</sup> Chamber with
          your inhaler helps ensure your medicine is delivered correctly.
        </H3>
        <P centered>
          People who use a valved holding chamber with their inhaler have been
          shown to have better control of their respiratory symptoms.
          <AnchorLink to="#ref1" title="references">
            <sup>1</sup>{" "}
          </AnchorLink>{" "}
          To improve drug delivery, global respiratory guidelines recommend the
          use of a chamber device along with a metered dose inhaler (MDI) in
          patients of all ages.
        </P>
      </SmallContainer>

      <Container>
        <Grid>
          <Features />
        </Grid>
      </Container>
      <Container>
        <FlexGrid>
          <ResourceCard
            svg="hcp"
            title="Healthcare Professionals Resources"
            description="<p>Clinical information and resources to help you support your patients. See the latest studies, research and related information.</p>"
            link="/resources/healthcare-professionals#hcp"
            linkText="View Healthcare Professionals Resources"
          />
          <ResourceCard
            image={data.lifeEffects}
            title="Teva Life Effects"
            description="<p>Initiative shaped by patients, for patients. Life Effects tips and technologies to make things a little easier</p>"
            externalLink="https://lifeeffects.teva/eu/"
            linkText="Visit Life Effects"
          />
          <ResourceCard
            svg="instructions"
            title="How To Use"
            description="<p>Explore our products, instructional videos or download our easy to use instructions for using and cleaning your device.</p>"
            link="/products#howto"
            linkText="View Instructions"
          />
        </FlexGrid>
      </Container>
      {/* <Container>
        <Grid>
          <GridItem>
            <StaticImage
              src="../images/life-effects-thumb.jpg"
              alt="Life Effects"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="cover"
              className="product-image"
            />
            <Caption>
              <H4>Teva Life Effects</H4>
              <P>
                Initiative shaped by patients, for patients. Life Effects tips
                and technologies to make things a little easier.
              </P>
              <ExitRamp
                trigger={<Button type="button">Visit Page</Button>}
                modalLink={"https://lifeeffects.teva/eu/"}
              />
            </Caption>
          </GridItem>
          <GridItem>
            <StaticImage
              src="../images/ltr-thumb.jpg"
              alt="Lets Talk Respiratory"
              placeholder="blurred"
              layout="fixed"
              loading="lazy"
              objectFit="cover"
              className="product-image"
            />
            <Caption>
              <H4>Let’s Talk Respiratory</H4>
              <P>
                Let's Talk Respiratory is our educational programme of
                supportive materials and some of the latest news in respiratory.
              </P>
              <ExitRamp
                trigger={<Button type="button">Visit Page</Button>}
                modalLink={"https://letstalkrespiratory.ie/"}
              />
            </Caption>
          </GridItem>
        </Grid>
      </Container> */}
      <SmallContainer>
        <P id="ref1" style={{ lineHeight: "1em", marginBottom: ".5em" }}>
          <small>
            <strong>References:</strong>
          </small>
        </P>
        <P style={{ lineHeight: "1.25em" }}>
          <small>
            1: Levy ML, Hardwell A, McKnight E, Holmes J. Asthma patients’
            inability to use a pressurized metered-dose inhaler (pMDI) correctly
            correlates with poor asthma control as defined by the Global
            Initiative for Asthma (GINA) strategy: a retrospective analysis.
            Prim Care Respir J. 2013; Dec;22(4):406-11
          </small>
        </P>
      </SmallContainer>
    </motion.main>
  );
};

const VideoContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  padding: 0 0 56.25% 0;
  box-shadow: 0px 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    border-radius: 0.5rem;
  }
`;

const Caption = styled.div`
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding: 1.5rem;
  ${H4} {
    margin-bottom: 0.5em;
  }
  ${P} {
    line-height: 1.4em !important;
    font-size: 0.925rem !important;
    font-weight: 300;
    @media (min-width: 1150px) {
      font-size: 0.875rem !important;
    }
  }
  ${Button} {
    background: none;
    margin: 0;
    color: var(--primary-color);
    text-decoration: none;
    font-size: 0.875rem;
    font-family: "Helvetica Neue";
    font-weight: 700;
    padding: 0;
  }
`;

const GridItem = styled.div`
  display: grid;
  grid-gap: 0;
  grid-column: 1/7;
  grid-template-columns: 1fr;
  .gatsby-image-wrapper {
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
    padding: 3.5rem;
  }
  @media (min-width: 768px) {
    &:nth-child(1) {
      grid-column: 2 / span 5;
    }
    &:nth-child(2) {
      grid-column: 7 / span 5;
    }
  }
  @media (min-width: 1150px) {
    grid-template-columns: 2fr 3fr;
    .gatsby-image-wrapper {
      padding: 0;
    }
  }
`;

export const query = graphql`
  query ArticleQuery {
    markdownRemark(frontmatter: { slug: { eq: "summer" } }) {
      id
      frontmatter {
        title
        description
        shortDescription
        slug
        mainImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    lifeEffects: file(relativePath: { eq: "life-effects-resource-logo.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default IndexPage;
